.buy-product {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-2);
}

.product-heading-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--surface-2);
  color: var(--fg);
  border-top: 1px solid var(--border-2);
  position: sticky;
  top: 0;
  z-index: 3;
  width: 100%;
}

.product-heading {
  display: flex;
  padding: 2.2rem 0;
  justify-content: space-between;
  width: 100%;
}

.product-title,
.product-price-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
}

.product-price-section {
  align-items: flex-end;
}

.product-name,
.product-price {
  font: var(--title);
  letter-spacing: -0.8px;
}

.product-info,
.product-mythic {
  font: var(--body-small);
  font-weight: 400;
  color: var(--muted);
  letter-spacing: -0.38px;
}

.product-details-section {
  display: flex;
  width: 100%;
  gap: 2rem;
  margin-top: 5.3rem;
}

.product-details-section > .product-details {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 70.4rem;
}

.product-detail-image {
  width: 70.4rem;
  height: 52rem;
  object-fit: cover;
  border-top-right-radius: 5.7rem;
}

.product-description,
.product-features {
  border-radius: 2.5rem;
  gap: 1px;
  background-color: var(--bg);
}

.product-description > :not(:last-child),
.product-features > :not(:last-child) {
  border-bottom: 1px solid var(--border);
}

.product-description > .title,
.product-features > .title {
  font: var(--heading-small);
  padding: 3rem;
}

.product-description > .text {
  font: var(--body-small);
  font-weight: 400;
  opacity: 0.6;
  padding: 3rem;
}

.product-features > .features {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2rem;

  font: var(--body-small);
  font-weight: 400;
  padding: 3rem;
  opacity: 0.6;
}

.features > .feature-item {
  display: flex;
  gap: 1rem;
}

.feature-item > .icon {
  fill: var(--surface);
}

.similar-products-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.similar-products > :first-child {
  padding-top: 15rem;
}

.similar-products > :last-child {
  padding-bottom: 15rem;
}

.similar-products > .home-title {
  margin-left: var(--content-margin);
}

.similar-product-items {
  display: flex;
  flex-direction: row;
  padding: 3.2rem 0;
  margin: -3.2rem 0;
  gap: 3.2rem;
  overflow-x: scroll;
}

.similar-product-items > :first-child {
  margin-left: var(--content-margin);
}

.similar-product-items > :last-child {
  margin-right: var(--content-margin);
}

.similar-product-items::-webkit-scrollbar {
  display: none;
}
