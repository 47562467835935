.navbar {
  background-color: var(--surface-2);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar.light {
  background-color: var(--bg);
}

.navbar.light > .navbar-inner {
  color: var(--fg-2);
}

.navbar-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 5rem;
  width: 100%;
  color: var(--fg);
  margin: 3.2rem 0;
  z-index: 10;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 6rem;
}

.navbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--body-small);
}

.navbar-profile {
  display: flex;
  gap: 0.9rem;

  background-color: var(--surface);
  border-top-right-radius: 1.8rem;
  color: var(--fg-2);
  /* padding: 1.4rem 4rem; */
}

.navbar-profile > .icon {
  fill: var(--fg);
}

.navbar-item > a,
.navbar-profile > a {
  text-decoration: none;
}

.navbar-item:hover {
  cursor: pointer;
}

.navbar .logo {
  height: 3.4rem;
}
