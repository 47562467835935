.products {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6rem;
}

.product-search-form {
  display: flex;
  flex-direction: row;
  border-radius: 2.5rem;
  width: 100%;
  gap: 1px;
}

.product-search-form > * {
  background-color: var(--bg);
}

.product-search-form > :first-child {
  border-radius: 2.5rem 0 0 2.5rem;
}

.product-search-form > :last-child {
  border-radius: 0 2.5rem 2.5rem 0;
}

.products-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50rem;
}

.product-items {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 3rem;
}

.products-load-more {
  margin-top: 6rem;
}

.product {
  display: flex;
  flex-direction: column;
  border-radius: 2.5rem;
  width: 33.6rem;
  letter-spacing: -0.42px;

  position: relative;
  transition: transform 0.2s;
}

.product:hover {
  transform: scale(1.1);
  z-index: 2;
  cursor: pointer;
}

.product > :first-child {
  border-radius: 2.5rem 2.5rem 0 0;
}

.product > :last-child {
  border-radius: 0 0 2.5rem 2.5rem;
}

.product-img {
  width: 33.6rem;
  height: 26.6rem;
  object-fit: cover;
}

.product > .title {
  display: flex;
  align-items: center;
  font: var(--heading-small);
  padding-left: 3rem;
  height: 9rem;
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-details > .detail-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.8rem;
  padding-left: 4rem;
  font: var(--body-medium);
  text-transform: capitalize;
}

.product-details > :not(:last-child) {
  border-bottom: 1px solid var(--border);
}

.product-details > .detail-item > .icon {
  width: 1.6rem;
  height: 1.6rem;
}

.tier-icon {
  height: 2.2rem;
  width: auto;
  position: absolute;
  top: 2rem;
  right: 2rem;
}
