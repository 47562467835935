.select {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font: var(--body) !important;
  padding: 5rem 0;
  width: 100%;
  border-radius: 2.5rem;
}

.select-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}

.dropdown {
  width: 100%;
  position: relative;
  background-color: var(--bg) !important;
}

.select.error {
  color: var(--err);
}

.menu {
  width: 100%;
  position: absolute;
  height: fit-content;
  z-index: 1;
  border-radius: 0.4rem;
  background-color: var(--fg);
}

.select:hover,
.menu :hover,
.select > input {
  cursor: pointer !important;
}

.menu > .clear {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  width: 100%;
  font: var(--body-small);
  padding: 1rem;
  border-bottom: 1px solid rgba(13, 22, 38, 0.1);
}

.select__control {
  background: var(--bg) !important;
  border: none !important;
  box-shadow: none !important;
}

.select__menu {
  background: var(--bg) !important;
  font: var(--body-small);
  padding: 0.2rem;
  margin: 0 !important;
}

.select__menu-list {
  padding: 0 !important;
}

.select__menu-list::-webkit-scrollbar {
  background-color: var(--bg);
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 2.5rem;
}

.select__menu-list::-webkit-scrollbar-thumb {
  background: var(--outline);
  border-radius: 0.7rem;
  border: 0.3rem solid var(--bg);
}

.select__menu-list::-webkit-scrollbar-corner {
  background: var(--bg);
}

.menu > :first-child {
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
}

.menu > :last-child {
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}

.select__placeholder {
  color: var(--muted) !important;
  font: var(--body-small);
}

.select__single-value {
  color: var(--white) !important;
}

.select__option--is-focused {
  background-color: var(--outline) !important;
}

.select__option--is-selected {
  background-color: var(--surface) !important;
}

.select__input-container {
  font: var(--body-small) !important;
}

.dropdown.unsearchable .select__control {
  display: none !important;
}
