table {
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0.8rem;
  font: var(--body-small);
}

th {
  text-align: center;
}

td {
  font-weight: 500;
  white-space: unset;
}

td > .positive {
  color: var(--success);
}

td > .negative {
  color: var(--err);
}

td > .warning {
  color: var(--warning);
}

thead > tr {
  border-bottom: 1px solid var(--border);
}

tbody:before {
  content: "@";
  display: block;
  line-height: 20px;
  text-indent: -99999px;
}

tbody > * {
  border-radius: 2rem;
  padding: 0.8rem;
}

tbody > :nth-child(odd) {
  background-color: var(--bg-2);
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 2rem;
}

.pagination {
  display: flex;
  gap: 0.1rem;
  align-items: center;
  justify-content: center;
}

.pagination > .pageItem {
  padding: 1.2rem;
  border: 1px solid var(--border);
  border-radius: 1.2rem;
  background-color: var(--fg);
}

.pagination-info {
  font: var(--body-small);
}

.table-wrapper .actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
