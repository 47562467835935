.load-wrapper {
  height: auto;
  align-self: center;
  justify-self: center;
}

.loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  width: 100%;
  height: 100%;
}

.load-wrapper.small {
  width: 12.5px;
  height: 12.5px;
}

.load-wrapper.normal {
  width: 25px;
  height: 25px;
}

.load-wrapper.big {
  width: 50px;
  height: 50px;
}

.loader .path {
  stroke: var(--surface);
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
