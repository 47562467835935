.body {
  font: var(--body);
}

.description {
  font: var(--description);
  text-align: center;
}

.heading-small {
  font: var(--heading-small);
}

.heading {
  font: var(--heading);
}

.heading-large {
  font: var(--heading-large);
}

.paragraph {
  font: var(--paragraph);
}

.body-small {
  font: var(--body-small);
}

.footer-item {
  font: var(--footer-item);
}

.display {
  margin: 0;
  font: var(--display);
}

a {
  color: var(--gray);
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: bold;
}

.lighter {
  font-weight: lighter;
}
