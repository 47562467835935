.reviews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
}

.review-item {
  display: flex;
  flex-direction: column;
  width: 33.6rem;
  height: 43.1rem;
}

.review-text {
  font: var(--heading-small);
  flex: 1;
  margin-top: 4rem;
  letter-spacing: -0.6px;
}

.review-text::before,
.review-text::after {
  content: '"';
}

.review-customer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  padding: 4.6rem 0;
  padding-left: 4rem;
  justify-self: baseline;

  background-color: var(--bg);
}

.customer-name,
.customer-role {
  font: var(--body-small);
}

.customer-role {
  font-weight: 400;
  color: var(--muted);
}
