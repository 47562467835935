.non-ideal-state {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  justify-self: center;
  width: 100%;
  gap: 1.6rem;
}

.non-ideal-state > .emote {
  font: var(--heading-small);
  color: var(--muted);
}

.non-ideal-state > .text {
  font: var(--heading-small);
  white-space: nowrap;
}

.non-ideal-state > .subtext {
  font: var(--body-small);
  white-space: nowrap;
  color: var(--muted);
  font-weight: 400;
}
