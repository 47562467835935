.button {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font: var(--body-medium);
}

.button.kind1 {
  border-top-right-radius: 1.8rem;
}

.button.kind2 {
  background-color: var(--surface);
  border-radius: 1.8rem;
}

.button:hover {
  filter: brightness(85%);
}

.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button .loader .path {
  stroke: var(--fg);
}

/* size related css */
.button.normal {
  min-height: 5rem;
  max-height: 7rem;
  padding: 0 4rem;
}

.button.small {
  min-height: 3rem;
  max-height: 5rem;
  padding: 0 1.2rem;
  font: var(--body-tiny);
  border-radius: 0.8rem;
}

/* background color related css */

.button.dark {
  background-color: var(--fg-2);
}

.button.primary {
  background-color: var(--surface);
}

.button.negative {
  background-color: var(--err-2);
}

.button.positive {
  background-color: var(--success-2);
}

.button.fluid {
  width: 100%;
}

.button .icon {
  fill: var(--fg-2);
  width: 1.8rem;
  height: auto;
}
