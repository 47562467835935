.my-account {
  display: flex;
  flex-grow: 1;
  background-color: var(--bg-2);
}

.my-account-sidebar {
  display: flex;
  flex-direction: column;
  gap: 8rem;

  margin-left: var(--outer-margin);
  padding: 10rem 11.3rem 0 8rem;

  font: var(--body-medium);
  font-weight: 600;
  letter-spacing: -0.02em;

  width: 32rem;
}

.my-account-content {
  display: flex;
  flex-direction: column;

  padding: 10rem calc(var(--outer-margin) + 8rem) 10rem 8rem;

  flex-grow: 1;
  gap: 6rem;

  background-color: var(--bg);
}

.my-account-content > .title {
  font: var(--title);
  font-weight: 600;
}

.sidebar-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  opacity: 0.3;
}

.sidebar-item.active {
  opacity: 1;
}

.sidebar-item:hover {
  cursor: pointer;
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;
  height: 12rem;
}

.user-info {
  text-align: center;
}

.profile-icon {
  width: 6rem;
  height: auto;
  border-radius: 50%;
}

.username {
  font: var(--heading-small);
}

.email {
  font: var(--body-medium);
  color: var(--muted);
}

.joined {
  font: var(--body-small);
  color: var(--muted);
}

.my-orders {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
}

.accounts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.accounts-list {
  width: 100%;
  text-align: left;
  white-space: pre-line;
}

.toggle-accounts:hover {
  cursor: pointer;
}

.table-filters {
  display: flex;
  gap: 1rem;
  font: var(--body-medium);
}

.filter-item {
  padding: 1rem;
  border: 1px solid var(--border);
  border-radius: 1.2rem;
}

.filter-item.selected {
  background-color: var(--success);
}

.filter-item:hover {
  cursor: pointer;
}
