.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 63.6rem;
}

.product-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15rem;
  padding-top: 18.9rem;
  padding-bottom: 22.6rem;
  width: 100%;
  background-color: var(--bg-2);
}

.reviews-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15rem 0;
  background-color: var(--bg-2);
}

.home-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  min-width: var(--outer);

  height: 75rem;
  z-index: 1;
}

.home-gradient::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(
    198.73deg,
    rgba(0, 0, 0, 0.0001) 15.31%,
    rgba(0, 0, 0, 0.837986) 67.37%
  );
}

.home-gradient > video {
  min-width: 100%;
  object-fit: cover;
  height: 100%;
}

.banner {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 11.8rem 0;
  gap: 2.2rem;
  color: var(--fg);
  text-align: left;
  white-space: pre-wrap;
}

.banner > * {
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: left;
  white-space: pre-wrap;
}

.banner > .title {
  font: var(--display);
  letter-spacing: -2.01026px;
}
.banner > .description {
  font: var(--body);
  font-weight: 400;
  opacity: 0.6;
}

.info-section {
  display: flex;
  gap: 6rem;
  justify-content: space-between;
  z-index: 4;
}

.info-img {
  position: relative;
  top: 15rem;
}

.info-img,
.faq-img {
  width: 70.4rem;
  height: 65.7rem;
  box-shadow: 0px 62px 54px rgba(0, 0, 0, 0.3);
}

.info-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin-top: 26.3rem;
  margin-bottom: 13.1rem;
  margin-right: var(--outer-margin);
  height: 37.4rem;
}

.home-title {
  display: flex;
  flex-direction: column;
  gap: 1.7rem;

  font: var(--heading);
  letter-spacing: -1.25px;
}

.home-title .yl {
  width: 16.9rem;
}

.info-details-inner {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2rem;
}

.info-details-inner > .description {
  font: var(--body);
  font-weight: 400;
  opacity: 0.6;
  text-align: left;
}

.info-details > .more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 2.5rem 4rem;
  width: fit-content;
  height: fit-content;
  gap: 2rem;

  font: var(--body-medium);
  color: var(--fg);
  background-color: var(--surface-2);
}

.more > .arrow {
  fill: var(--surface);
}

.faq-section {
  display: flex;
  justify-content: space-between;
  z-index: 4;
  padding-top: 15rem;
}

.faq-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  gap: 2rem;
  margin-left: var(--min-margin);
}

.faq-items {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 49.1rem;
  padding-bottom: 15rem;
}

.faq-item {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.faq-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.faq-title > span {
  font: var(--body);
  opacity: 0.6;
  letter-spacing: -0.5px;
  flex: 1;
}

.faq-title:hover {
  cursor: pointer;
}

.faq-content {
  font: var(--body-small);
  font-weight: 400;
  opacity: 0.6;
  letter-spacing: -0.5px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 0.5, 0, 1);
}

.faq-content.opened {
  max-height: 500px;
  transition: max-height 0.7s ease-in-out;
}

.faq-img {
  position: relative;
  top: calc(-15rem + var(--offset));
  right: 0;
}

.faq-icon {
  height: 2rem;
  width: 2rem;
  transition: transform 0.5s;
}

.faq-icon.opened {
  transform: rotate(180deg);
}
