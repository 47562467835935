:root {
  --font-family: "DM Sans", sans-serif;
  --body: normal normal bold 2rem / normal var(--font-family);
  --body-tiny: normal normal bold 1.2rem / normal var(--font-family);
  --body-small: normal normal bold 1.5rem / normal var(--font-family);
  --body-medium: normal normal bold 1.7rem / normal var(--font-family);
  --display: normal normal bold 8rem / 8rem var(--font-family);
  --heading: normal normal bold 5rem / normal var(--font-family);
  --heading-small: normal normal bold 2.4rem / normal var(--font-family);
  --heading-medium: normal normal bold 4.2rem / normal var(--font-family);
  --title: normal normal bold 3.2rem / normal var(--font-family);

  --bg: #fff;
  --bg-2: #f5f5f5;
  --outline: #adb5bd;
  --surface: #ffac12;
  --surface-2: #000;
  --fg: #fff;
  --fg-2: #000;
  --muted: #979797;
  --border: #e4e4e4;
  --border-2: #191919;
  --err: #ea3943;
  --err-2: #ea3943ee;
  --success: #16c784;
  --success-2: #16c784ee;
  --warning: #eecc00;

  --outer: 144rem;
  --inner: 106.9rem;
  --outer-margin: max(calc((100vw - var(--outer)) / 2), 0rem);
  --min-margin: calc((var(--outer) - var(--inner)) / 2);
  --content-margin: max(var(--min-margin), calc((100vw - var(--inner)) / 2));
  --offset: -7.6rem;
}

.yl {
  background: linear-gradient(90deg, var(--surface) 0%, var(--fg-2) 94.96%);
  border-radius: 0.2rem;
  height: 0.4rem;
}
