::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--muted);
  opacity: 1; /* Firefox */
  font-weight: 400;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--muted);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--muted);
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
}

form > .heading {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;
}

form > .message {
  font: var(--body-small);
  text-align: center;
  color: var(--success);
  min-height: 2rem;
}

form > .error {
  color: var(--err);
}

form > .heading > .title {
  font: var(--body-small);
  font-weight: 500;

  text-transform: uppercase;
  color: var(--surface);
}

form > .heading > .subtitle {
  font: var(--title);
}

form > .extra {
  font: var(--body-small);
  font-weight: 400;
  text-align: center;
}

form > .extra a {
  color: blue;
  text-decoration: none;
}

input,
textarea {
  padding: 0.8rem;
  border: none;
  border-radius: 0.8rem;
  resize: none;
  width: 100%;
  height: 2.4rem;
  font: var(--body-small);
  font-weight: 500;
}

input:focus,
textarea:focus {
  outline: none;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.input > .label {
  font: var(--body-small);
  font-weight: 500;
}

.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: 0.6rem;
  padding: 1.2rem;
  gap: 0.8rem;
}

.input-group.error {
  border: 1px solid var(--err);
}

.input-group > .icon {
  height: 2.4rem;
  width: 2.4rem;
  padding: 0.1rem;
}

.input-group > .icon > svg {
  height: 100%;
  width: 100%;
  color: var(--muted);
}

.input > .error-message {
  color: var(--err);
  font: var(--body-tiny);
  font-weight: 400;
  height: 1.2rem;
}

.recaptcha {
  padding-bottom: 1.2rem;
}
