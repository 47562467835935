body {
  margin: 0;
  background-color: var(--bg);
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 100vh;
  min-width: var(--outer);

  z-index: 1;
}

.contents {
  display: flex;
  flex-grow: 1;
  align-self: center;
  justify-content: center;
  min-height: 90vh;
  width: 100%;
}

.container {
  align-self: center;
  max-width: var(--inner);
  min-width: var(--inner);
}

.outer-container {
  max-width: var(--outer);
  min-width: var(--outer);
}

.toast {
  font: var(--body-small);
}
