.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 54.4rem;

  padding: 4rem;
  border-radius: 1.2rem;
  border: 1px solid var(--border);
  gap: 4rem;
}
