.footer {
  display: flex;
  width: 100%;
  background-color: var(--surface-2);
  color: var(--fg);
  align-items: center;
  justify-content: center;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8rem 0;
  gap: 5rem;
}

.footer-divider {
  width: 100%;
  background: #ffffff;
  opacity: 0.1;
  height: 1px;
}

.sales {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.sales > .navbar-profile {
  align-items: center;
  justify-content: center;
  font: var(--body-small);
}

.sales-text {
  font: var(--heading-medium);
}

.sales-text > span {
  color: var(--surface);
}

.footer-nav {
  display: flex;
  flex-direction: row;
  gap: 16.9rem;
}

.footer-socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.social-links {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.social-link {
  transition: 0.1s;
}

.social-link:hover {
  margin-top: -4px;
  cursor: pointer;
}

.social-icon {
  height: 2.2rem;
  width: auto;
}

.footer-about,
.footer-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-links {
  min-width: 11.5rem;
}

.footer-title,
.footer-text {
  font: var(--body-small);
}

.footer-text {
  font-weight: 400;
  color: var(--muted);
}

.footer-links > a {
  text-decoration: none;
}

.footer-links > a:hover {
  text-decoration: underline;
}
