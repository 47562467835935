.checkout {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 2.5rem;
  flex: 1;
  font: var(--body-small);
  font-weight: 400;
  gap: 1rem;
  height: fit-content;
}

.checkout > .tier {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  background-color: var(--border);
  border-radius: 1rem;
  padding: 1.9rem;
  text-transform: capitalize;
}

.checkout > .tier > .icon {
  height: 2.2rem;
  width: auto;
}

.checkout-select {
  border: 1px solid var(--border);
  border-radius: 1rem;
  padding: 2rem;
  font: var(--body-small) !important;
  font-weight: 400 !important;
}

.purchase {
  background-color: var(--surface-2) !important;
  color: var(--fg);
  padding: 2.5rem 0 !important;
  gap: 3.5rem;
}

.purchase > .icon {
  fill: var(--surface);
  width: 2.2rem;
  height: auto;
}
